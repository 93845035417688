import {useEffect, useRef, useState} from "react";
import * as employeeApi from 'api/employee';
import * as jwt from "utils/jwt";

const constant = {

    class: {
        button: (() => {

            const buttonClass = [
                "profile-button",
                "fixed",
                "inline-block",
                "overflow-visible",
                "whitespace-nowrap",
                "cursor-pointer",
                "w-8",
                "h-8",
                "flex",
                "justify-center",
                "items-center",
                "rounded-full",
                "shadow",
                "bg-blue-500",
                "hover:bg-blue-600",
                "text-white",
                "right-4",
                "top-4"
            ];

            return {

                normal: buttonClass.join(" "),
                focus: [ ...buttonClass, "bg-blue-600" ].join(" ")

            };

        })(),

        list: (() => {

            const listClass = [
                "absolute",
                "top-full",
                "right-0",
                "cursor-default",
                "text-slate-500",
                "shadow",
                "text-sm",
                "rounded",
                "transition-all"
            ];

            return {

                show: [ ...listClass, "opacity-100", "scale-y-100", "translate-y-0", ].join(" "),
                hide: [ ...listClass, "opacity-0", "scale-y-0", "-translate-y-1/2" ].join(" ")

            };

        })()



    }

};

function Profile() {

    const [user, setUser] = useState({ empName: "-" });
    const fetchedEmployee = useRef(false);
    const [buttonClass, setButtonClass] = useState(constant.class.button.normal);
    const [listClass, setListClass] = useState(constant.class.list.hide);

    useEffect(() => {

        if( fetchedEmployee.current ){
            return;
        }

        fetchedEmployee.current = true;

        employeeApi.get().then(( response ) => {

            if (!response.data) {
                return;
            }

            setUser(response.data);

        });

    }, []);

    useEffect(() => {

        const onClickBody = () => {

            closeMenu();

        };
        document.body.addEventListener("click", onClickBody);

        return () => {

            document.body.removeEventListener("click", onClickBody);

        };

    }, []);

    const closeMenu = () => {
        setButtonClass( constant.class.button.normal );
        setListClass( constant.class.list.hide );
    };

    const openMenu = () => {
        setButtonClass( constant.class.button.focus );
        setListClass( constant.class.list.show );
    };

    const onClickProfileButton = ( event ) => {

        event.stopPropagation();

        if( constant.class.button.focus === buttonClass ) {

            closeMenu();
            return;

        }

        openMenu();

    };

    const onClickList = ( event ) => {
        event.stopPropagation();
    }

    const onClickLogoutButton = () => {
        jwt.delete();
    };

    return (
        <div className={buttonClass} onClick={onClickProfileButton}>
            <div>
                { user.empName[0] }
            </div>
            <div className={listClass} onClick={onClickList}>
                <div className="border-b px-4 py-2">
                    { `${user.empName} (${user.empOfficeName || '프로'}/${user.deptName || '-'})` }
                </div>
                <div className="px-4 py-2 font-bold cursor-pointer hover:bg-slate-50" onClick={onClickLogoutButton}>
                    logout
                </div>
            </div>
        </div>
    );

}

export default Profile;
