import * as commonApi from "api/common";

export const post = async (file) => {

    const method = 'POST';
    const url = `${process.env.REACT_APP_API_END_POINT}/file`;

    const body  = new FormData();
    body.append("file", file);

    const response = await commonApi.request(url, method, body);
    return response.json();

};