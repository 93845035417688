import 'App.css';
import {useRef, useState} from "react";
import Spinner from "components/Spinner";
import Profile from "components/Profile";
import * as jwt from "utils/jwt";
import * as fileApi from "api/file";
import * as fileDialog from "utils/fileDialog";
import Toast from "components/Toast";

jwt.get();

const constant = (() => {

    const commonButtonClasses = [
        "text-white",
        "py-1",
        "rounded",
        "mx-1",
        "transition-all",
        "duration-500"
    ];

    return {

        browseButtonClass: [
            ...commonButtonClasses,
            "bg-slate-500",
            "hover:bg-slate-600",
        ].join(" "),
        uploadButtonClass: [
            ...commonButtonClasses,
            "bg-blue-500",
            "hover:bg-blue-600"
        ].join(" "),
        uploadingButtonClass: [
            ...commonButtonClasses,
            "bg-blue-500/50",
            "cursor-not-allowed",
            "w-28"
        ].join(" "),

    };

})();

function App() {

    const [isUploading, setUploading] = useState(false);
    const [uploadButtonText, setUploadButtonText] = useState("Upload");
    const [uploadButtonClass, setUploadButtonClass] = useState( `${constant.uploadButtonClass} scale-x-0 w-0` );
    const [browseButtonClass, setBrowseButtonClass] = useState( `${constant.browseButtonClass} scale-x-1 w-24` );
    const [file, setFile] = useState({ name: "" });
    const [spinnerController, setSpinnerController] = useState();
    const [toastText, setToastText] = useState("업로드가 완료되었습니다.");
    const [toastVisible, setToastVisible] = useState(false);
    const [toastBackgroundColor, setToastBackgroundColor] = useState("rgb(239, 68, 68)");
    const dotIntervalId = useRef();


    const reset = () => {

        clearInterval( dotIntervalId.current );
        setFile( { name: "" } );
        setBrowseButtonClass( `${constant.browseButtonClass} scale-x-1 w-24` );
        setUploading( false );
        setUploadButtonClass( `${constant.uploadButtonClass} scale-x-0 w-0` );
        setUploadButtonText( `Upload` );
        setToastVisible(true);
        setTimeout( () => {

            setToastVisible( false );

        }, 3000 );

    };

    const uploadFile = async () => {

        spinnerController.start();
        setBrowseButtonClass( `${constant.browseButtonClass} scale-x-0 w-0` );
        setUploading( true );
        setUploadButtonClass( constant.uploadingButtonClass );
        setUploadButtonText( `Uploading` );

        let addDotUnit = 1;
        let nowDots = 0;
        const maxDots = 3;
        const minDots = 0;
        dotIntervalId.current = setInterval(() => {

            nowDots = nowDots + addDotUnit;
            if( nowDots === maxDots || nowDots === minDots ){
                addDotUnit = -addDotUnit;
            }

            setUploadButtonText( `${"".padEnd(nowDots, '\u00A0')}Uploading${"".padEnd(nowDots, ".")}` );
        }, 500);

        try{
            await fileApi.post( file );
            setToastText("업로드가 완료되었습니다.");
            setToastBackgroundColor("rgb(34, 197, 94)");
        }catch (e) {
            console.error(e);
            setToastText("관리자에게 문의하세요.");
            setToastBackgroundColor("rgb(239, 68, 68)");
        }finally {
            spinnerController.stop();
        }

    };

    const onClickBrowseButton = async () => {

        try {

            setFile( await fileDialog.open() );
            setUploadButtonClass(`${constant.uploadButtonClass} scale-x-1 w-24`);

        } catch (e) {

          console.warn(e);

        }

    };

    const onClickUploadButton = () => {

        uploadFile();


    };

    const onLoadSpinner = ( controller ) => {

        window.c = controller;
        setSpinnerController( controller );

    };

    const onStopSpinner = () => {

        reset();

    };

    return (
        <div className="fixed h-full w-full flex flex-col items-center justify-evenly">
            <Profile />
            <header>
                <h1 className="font-bold text-xl">
                    SGK File Uploader
                </h1>
            </header>
            <main className="h-[50%] text-center">
                <Spinner onLoad={onLoadSpinner} onStop={onStopSpinner} />
            </main>
            <div className="w-full h-6 text-center">
                { file.name }
            </div>
            <footer>
                <button className={browseButtonClass} disabled={ !spinnerController } onClick={onClickBrowseButton}>Browse</button>
                <button className={`${uploadButtonClass}`} disabled={ isUploading } onClick={onClickUploadButton}>{ uploadButtonText }</button>
            </footer>
            <Toast visible={toastVisible} text={toastText} backgroundColor={toastBackgroundColor} />
        </div>
    );
}

export default App;
