const queryStringParameter = `?cid=${process.env.REACT_APP_SSO_CLIENT_ID}&redirectUrl=${encodeURIComponent(window.location.origin)}`;
const loginPage = `${process.env.REACT_APP_SSO_END_POINT}/page/login${queryStringParameter}`;
const logoutPage = `${process.env.REACT_APP_SSO_END_POINT}/page/logout${queryStringParameter}`;

const get = () => {

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    if (query.logout) {
        sessionStorage.removeItem("sgk-sso-jwt");
    }

    // 로그인함
    if (query.jwt) {
        sessionStorage.setItem("sgk-sso-jwt", query.jwt);
        window.location.href = window.location.origin;
        return;
    }

    // jwt 없음
    const jwtFromStorage = sessionStorage.getItem("sgk-sso-jwt");
    if (jwtFromStorage === null) {
        window.location.href = loginPage;
        return;
    }

    try {

        const claim = jwtFromStorage.split(".")[1];

        // jwt 만료
        if (JSON.parse(atob(claim)).exp * 1000 < Date.now()) {
            sessionStorage.removeItem("sgk-sso-jwt");
            window.location.href = loginPage;
        }

        // 잘못된 jwt
    } catch (e) {

        sessionStorage.removeItem("sgk-sso-jwt");
        window.location.href = loginPage;

    }

    return jwtFromStorage;

};

const del = () => {

    window.location.href = logoutPage;

};

export {

    get,
    del as delete

}