import lottie from 'lottie-web';
import 'components/Spinner/index.css';
import * as animationData from 'assets/lottie/file.json';
import {useEffect, useRef} from "react";

function Spinner({ onLoad = () => {}, onStop = () => {} }) {

    const container = useRef();
    const isStopped = useRef( true );
    const animationItem = useRef();

    useEffect(() => {

        if( animationItem.current ){
            return;
        }

        animationItem.current = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            initialSegment: [100, 100],
            animationData,
        });

        window.a = animationItem.current;

        animationItem.current.addEventListener("complete", () => {
            isStopped.current = true;
            onStop();
        });

        onLoad({

            start: () => {

                if( !isStopped.current ){
                    return;
                }

                isStopped.current = false;
                animationItem.current.setLoop( true );
                animationItem.current.playSegments([[100, 120], [0, 120]], true);

            },

            stop: () => {

                const onDrawnFrame = () => {
                    animationItem.current.setLoop( false );
                    animationItem.current.removeEventListener("loopComplete", onDrawnFrame);
                    animationItem.current.playSegments([0, 100], true);
                }
                animationItem.current.addEventListener("loopComplete", onDrawnFrame);


            }

        });

    }, [onLoad, onStop]);


    return (
        <div ref={container} className="relative w-full h-full left-0 top-0" />
    );

}

export default Spinner;