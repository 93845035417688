const fileInput = document.createElement("input");
fileInput.type = "file";
window.f = fileInput;

const before = {

    promiseReject: () => {}

};

export const open = () => {

    before.promiseReject();
    return new Promise(( resolve, reject ) => {

        fileInput.click();

        const onChange = () => {

            fileInput.removeEventListener( "change", onChange );
            const file = fileInput.files[0];
            fileInput.value = '';
            before.promiseReject = () => {};
            resolve( file );

        };

        before.promiseReject = () => {
            fileInput.removeEventListener( "change", onChange );
            fileInput.value = '';
            reject("[fileDialog] Previous promise have not been resolved.\nThis means that the file dialog was previously just closed.");
        };

        fileInput.addEventListener( "change", onChange );

    });

};