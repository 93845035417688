import {useEffect, useState} from "react";

const constant = {

    class: {
        show: "opacity-100 bottom-28",
        hide: "opacity-0 bottom-0"
    }

};

function Toast({ visible, text, backgroundColor }) {

    const [ visibleClass, setVisibleClass ] = useState( constant.class.hide );
    useEffect(() => {

        if( visible ){
            setVisibleClass( constant.class.show );
            return;
        }

        setVisibleClass( constant.class.hide );

    }, [visible]);

    return (
        <div className={`fixed left-1/2 -translate-x-1/2 px-8 py-2 rounded text-white pointer-events-none shadow transition-all duration-1000 ${visibleClass}`} style={{backgroundColor}}>
            {text}
        </div>
    );

}

export default Toast;